:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
}

#liquidez {
    width: 100%;
}


.titulo {
    color:  #090F37;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    margin-bottom: -10px;
}

.sub_titulo {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #090F37;
}

.color-blue {
    color: var(--liberfin-color);
}

.center {
    text-align: center;
    align-items: center;
}

.margin-text {
    margin-left: 7.2rem !important;
}

.man-img {
    width: 490px;
    height: auto;
}

.col-6 > ul {
    list-style-type: none;
    margin: 0;
    text-align: left;
    margin-bottom: 40px;
}

.col-6 > ul > li {
    text-align: left;
    align-items: center;
    padding: 0 0 20px 5px;
}

.col-6 > ul > li > span {
    font-family:  'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #000000;
    padding-left: 10px;
}

@media screen and (min-width: 1120px) and (max-width: 1254px) {
    .man-img {
        width: 460px;
        height: auto;
    }
}

@media screen and (min-width: 992px) and (max-width: 1119px) {
    .man-img {
        width: 460px;
        height: auto;
        margin-left: -50px!important;
    }
}

@media screen and (max-width: 800px) {
    #liquidez {
        margin-top: 20px;
        width: 100%;
    }
    .man-img {
        margin-top: 60px;
        width: 320px;
        height: auto;
    }

    .w-75 {
        width: 100%!important
    }
}