:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
}
            
#footer {
    background-color: var(--liberfin-color);
    bottom: 0;
    position: absolute;
    width: 100%;
    color: #FFFFFF;
    justify-content: center;
}
            
.h-footer {
    min-height: 170px;
}
            
.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
}
            
.link {
    text-decoration: none;
    color: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
}
.link:hover {
    color: #FFFFFF;
    text-decoration: none;
    font-style: bold;
}
            
.logo {
    display: block;
    padding: 40px 20px 0px 80px;
    align-items: center;
}
            
.footer-logo {
    margin-right: 5%;
}
.footer-logo-padding {
    padding-left: 4.5%!important;
}

.phone-me {
    margin-right: 100px;
}
            
.footer_fonts {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
}
            
.textos {
    margin-left: 5%;
    width: 20%;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
}
            
.terminos {
    margin-left: 5%;
    width: 25%;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
}
            
.padding-phones {
    padding-bottom: -3px;
    margin-bottom: 0!important;
}
            
.telefonos {
    margin-left: -5%;
    width: 25%;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
}
            
.icons {
    display: flex;
    align-items: flex-end;
    width: 5%;
    margin-top: 55px;
    margin-left: -5%;
}
            
.footer-icons {
    margin-top: 2rem;
}
            
.padding-i {
    padding-left: 10px;
    padding-right: 10px;
}
            
.ml {
    margin-left: 5;
}

@media screen and (min-width: 1px) and (max-width: 950px) {
    .phone-me {
        margin-right: 0px;
    }
}
            
@media screen and (max-width: 800px) {
    #footer {
        background-color: var(--liberfin-color);
        bottom: 0;
        width: 100%;
        color: #FFFFFF;
    }
    .footer-mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;
        text-align: center;
    }
    .padding-mobile {
        padding-left: 5%;
        padding-right: 5%!important;
    }
    .logo {
        display: block;
        padding: 20px 10px 0px 20px;
        align-items: center;
    }
    .icons {
        display: none;
    }
    .footer-logo-padding {
        padding-left: 0%!important;
    }
}