:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
}

.header {
    height: 80px;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
}

.header_logo {
    margin-left: 80px;
    padding-right: 20px;
    margin-right: 10px;
    align-items: center;
    display: flex;
}

.img-logo {
    min-width: calc(153px - 4%);
    min-height: calc(50px - 4%);
    max-width: calc(153px - 4%);
    max-height: calc(50px - 4%);
}

.btn-blue {
    background-color: var(--liberfin-color);
    border-color: var(--liberfin-color);
    color: #FFFFFF;
}

.btn-blue:hover {
    background-color: var(--liberfin-color-hover);
    border-color: var(--liberfin-color-hover);
}

.h-content {
    padding-left: 20px;
    float: right;
    padding-right: 5%;
    display: block;
}

.liberfin_logo {
    display: none;
}

.h-content>.ul-menu {
    text-align: left;
    margin: 0 auto 0 auto;
    width: 100%;
    display: block;
    padding: 0;
}

.h-content>.ul-menu>li {
    text-align: center;
    display: block;
    padding: 0;
    margin: 0;
    float: left;
}

.btn-mobile {
    display: none;
    text-align: center;
    align-items: center;
}

.h-content>.ul-menu>li>a {
    display: block;
    /* font-family: Poppins; */
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: 15px;
    font-weight: 700;
    padding: 25px 32px 10px 18px;
    line-height: 17px;
    text-decoration: none;
    position: relative;
}

.header-txt {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
    text-decoration: none;
}

.h-btn {
    background-color: var(--liberfin-color);
    border: none;
    border-color: var(--liberfin-color);
    border-radius: 5px;
    color: #FFFFFF;
    width: 150px;
    height: 40px;
    left: 78%;
    top: 15px;
    padding: 5px 0px 5px 0px;
    outline: none;
    align-items: center;
    text-align: center;
    margin-top: 15px;
    cursor: pointer;
    text-decoration: none;
}

.h-btn:hover {
    background-color: var(--liberfin-color-hover);
    border-color: var(--liberfin-color-hover);
    color: #FFFFFF;
}

.btn-text {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.my-icon {
    margin-top: 8px;
    margin-left: 2px;
    padding-top: 5px;
}

.bg-side {
    background-color: var(--liberfin-color);
}

.fs-header-8 {
    font-size: 14px;
}

.fs-header-9 {
    font-size: 15px;
}

.btn-login {
    padding: 14px 34px;
}

.margin-button-menu {
    margin-left: 60%;
}

.ul-header {
    padding-left: 20px!important;
}

.li-header {
    align-items: center!important;
}

ul > div > li {
    display: block!important;
}

.mobile-menu-font {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

    text-align: center;
    color: #FFFFFF;
}

.hr-menu {
    color: #6188F7;
    border: 1px solid #6188F7;
}

@media screen and (min-width: 250px) and (max-width: 991px) {
    .m-navbar {
        width: 95% !important;
    }
}

@media screen and (min-width: 992px) and (min-width: 1026px) {
    .m-navbar {
        width: 97% !important;
    }
}

@media screen and (min-width: 1027px) and (max-width: 1199px) {
    .m-navbar {
        width: 95% !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1299px) {
    .m-navbar {
        width: 94% !important;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1380px) {
    .m-navbar {
        width: 90% !important;
    }
}

@media screen and (min-width: 1381px) {
    .m-navbar {
        width: 85% !important;
    }
}

