:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
}

#section1 {
    margin-top: 40px;
    padding-left: 25%;
    padding-right: 25%;
}

#cuentanos {
    width: 100%;
    margin-bottom: 200px;
}

.container-empresa {
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
}



.column-terminos {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    flex-wrap: wrap !important;
}

.padding-terminos {
    padding-left: 120px;
}

.padd-check {
    padding-left: 0px!important;
}

.column-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap !important;
}

.cuentanos-tile {
    color: #090F37;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
}

.company_subtitle {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #575F6E;
    margin-top: 5px;
}

.color-blue {
    color: var(--liberfin-color);
}

.form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
}

.form-group label {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #090F37;
}

.form-group select {
    display: inline-block;
    width: 100%;
    height: 49px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border: 1px solid #6188F7;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}



.input-text {
    width: 100%;
    height: 49px;
    border: 1px solid #6188F7;
    box-sizing: border-box;
    border-radius: 4px;
    background: #FFFFFF;
    font-size: 1rem;
}

.input-text:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(97, 144, 255, 0.25);
}

.input-number {
    width: 100%;
    height: 49px;
    border: 1px solid #6188F7;
    box-sizing: border-box;
    border-radius: 4px;
    background: #FFFFFF;
    font-size: 1rem;
}

.input-number:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(97, 144, 255, 0.25);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.input-pass {
    width: 100%;
    height: 49px;
    border: 1px solid #6188F7;
    box-sizing: border-box;
    border-radius: 4px;
    background: #FFFFFF;
}

input[type=password] i {
    margin-right: 5px;
}

.input-pass:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(97, 144, 255, 0.25);
}



.end {
    float: end;
}

center {
    text-align: center;
}

.terminos-font {
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 0.6rem;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #090F37;
    padding-top: 10.5px;
}

.check-border {
    border-radius: 2.6px !important;
}

.check-border:checked {
    border-radius: 2.6px !important;
    background-color: var(--liberfin-color);
    border-color: var(--liberfin-color);
}

.h-btn {
    background-color: var(--liberfin-color);
    border: none;
    border-color: var(--liberfin-color);
    border-radius: 5px;
    color: #FFFFFF;
    width: auto;
    height: 40px;
    left: 78%;
    top: 15px;
    padding: 9px 7px 5px 7px;
    outline: none;
    align-items: center;
    text-align: center;
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.h-btn:hover {
    background-color: var(--liberfin-color-hover);
    border-color: var(--liberfin-color-hover);
}

.my-icon {
    margin-top: 8px;
    margin-left: 2px;
    padding-top: 5px;
}

.icon-button-blue {
    position: relative;
    color: var(--liberfin-color);
}

.span_icon > .icon-button-blue > svg > path:hover {
    fill: #FFFFFF;
}

.cancel_button {
    outline: none !important;
    text-decoration: none;
}

.form-check-input-lg {
    font-size: 24px;
}

.width-btutton {
    width: 210px!important;
}

.cancelar {
    outline: none;
    color: #090F37;
    font-family: 'Poppins', sans-serif;
    font-style: 'bold';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    text-align: center;
}

.red-text {
    color: red;
    margin-top: -8px;
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-size: 12px;
}

@media screen and (max-width: 800px) {
    #cuentanos {
        margin-bottom: 400px;
    }
    #section1 {
        margin-top: 20px;
        padding-left: 5%;
        padding-right: 5%;
    }
    .cuentanos-tile {
        color: #090F37;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        text-align: center!important;
    }
    .company_subtitle {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #090F37;
    }
    .column-terminos {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100% !important;
        flex-wrap: wrap;
    }
    .pl {
        padding-left: 0%;
    }
    .check-mobile {
        text-align: start!important;
        align-items: flex-start!important;
    }
    .text-end {
        text-align: start!important;
    }
    .margin-cols {
        margin-top: 0px;
    }
    .padding-terminos {
        padding-left: 50px;
    }
}