:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
}


/* .section1 {
    background-image: url(../img/section1.png);
    position: absolute;
    width: 850px;
    height: 670px;
    left: 295px;
    top: 80px;
    margin-bottom: 15px;
    z-index: -1;
} 
*/

.btn-blue {
    background-color: var(--liberfin-color);
    border-color: var(--liberfin-color);
    color: #FFFFFF;
}

.btn-blue:hover {
    background-color: var(--liberfin-color-hover);
    border-color: var(--liberfin-color-hover);
}

.btn-blue-light {
    background-color: #FFFFFF;
    border-color: var(--liberfin-color);
    color: var(--liberfin-color);
}

.btn-blue-light:hover {
    background-color: var(--liberfin-color);
    border-color: var(--liberfin-color);
    color: #FFFFFF;
    fill: #FFFFFF;
}

.section1 {
    width: 100%;
    margin-top: 5px;
    min-height: 436px;
}

.index {
    margin-left: -260px;
    z-index: -1
}

.z-section .main {
    position: relative;
    margin-left: -20%;
    z-index: -1;
}

.pl {
    padding-left: 5%;
}

.gradient {
    background: linear-gradient(237.63deg, rgba(255, 255, 255, 0.8) -7.38%, rgba(255, 255, 255, 0) 84.46%);
    backdrop-filter: blur(40px);
    border-radius: 10px;
}

.col-padding {
    padding: 30px 0 20px 30px;
}

.col-padding>p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
}

.p-space {
    font-family: 'Poppins', sans-serif;
    width: 70%;
    max-width: 70%;
    margin-top: 10px;
}

.presentation {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 60px;
    color: #090F37;
}

.sub-presentation {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 60px;
    color: var(--liberfin-color);
}

.imgSize {
    width: 97%;
    height: 96%;
}

.btn-finan {
    padding: 12px 12px;
}

.bg-index-section {
    background-position: top center;
}

@media screen and (max-width: 1200px) {
    .presentation {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 46px;
        line-height: 50px;
        color: #090F37;
    }
    
    .sub-presentation {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 50px;
        color: var(--liberfin-color);
    }
}

@media screen and (max-width: 800px) {
    .bg-index-section {
        margin-top: 60px;
    }
    .section1 {
        width: 100%;
        margin-top: 120px;
    }
    .imgSize {
        width: 100%;
        height: auto;
    }
    .col-padding {
        text-align: center;
        align-items: center;
    }
    .gradient {
        background: none;
        backdrop-filter: none;
        border-radius: 0px;
    }
    .presentation {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 44px;
        line-height: 60px;
        color: #090F37;
    }
    .sub-presentation {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 38px;
        line-height: 60px;
        color: var(--liberfin-color);
    }
    .p-space {
        font-family: 'Poppins', sans-serif;
        width: 100%;
        max-width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        margin-top: 10px;
    }
    .margin-mobile {
        margin-top: -150px;
    }
    .padding-mobile {
        padding-right: 5%;
    }
    .img-mobile {
        min-height: 654px;
    }
    .img-mobile img {
        top: 352px;
        text-align: center;
        position: absolute;
        left: 0;
    }
    .padding-finan-title {
        padding-left: 0px !important;
    }
    .form {
        padding: 0px 10px !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .imgSize {
        width: 100%;
        height: 106%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .imgSize {
        width: 98%;
        height: 110%;
    }

    .margin-1199 {
        margin-left: 15%;
    }
}

@media screen and (min-width: 801px) and (max-width: 991px) {
    .imgSize {
        display: block!important;
        width: 100%;
        height: auto;
    }
}