:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
}

#about1 {
    margin-top: 50px;
    margin-bottom: 120px;
}

.center {
    text-align: center;
}

.about_title {
    color:  #090F37;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    margin-bottom: -10px;
    align-items: center;
    width: 110%;
    margin-left: -10px;
}

.about-p-widht{
    width: 85%;
}

.about_sub_titulo {
    margin-top: 22px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    font-size: 18px;
    color: #090F37;
}

.about-list {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 20px !important;
}

.about-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.about-list li span {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 18px;

    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-size: 15px;
    line-height: 23px;
    color: #000000;
}

.color-blue {
    color: var(--liberfin-color);
}


.col-6 > ul {
    list-style-type: none;
    margin: 0;
    text-align: left;
    margin-bottom: 40px;
    padding-left: 40px;
}

.col-6 > ul > li {
    text-align: left;
    align-items: center;
    padding: 0 0 20px 5px;
}

.col-6 > ul > li > span {
    font-family:  'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #000000;
}

.about-card-button {
    background-color: transparent;
    border: 2px solid;
    border-color: var(--liberfin-color);
    border-radius: 5px;
    color: var(--liberfin-color);
    width: auto;
    height: auto;
    padding: 15px 10px 15px 10px;
    outline: none;
    align-items: center;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    font-size: 14px;
    cursor: pointer;
}

.about-card-button:hover{
    background-color: var(--liberfin-color);
    color: #ffffff;
    cursor: pointer;
}

.web-view {
    margin-top: 80px;
}

.hand-img {
    width: 100%;
    height: auto;
    z-index: -1;
    position: relative;
}

@media screen and (min-width: 941px) and (max-width: 991px){
    #about1 {
        margin-top: 10px;
        margin-bottom: 190px;
    }
    .web-view {
        margin-top: 0px;
    }
    .hand-img-conter {
        padding-left: 30px;
        align-items: center;
    }
    .hand-img {
        width: 140%;
        height: auto;
        z-index: -1;
        position: relative;
    }
}
@media screen and (min-width: 800px) and (max-width: 940px){
    #about1 {
        margin-top: 10px;
        margin-bottom: 190px;
    }
    .web-view {
        margin-top: 0px;
    }
    .hand-img-conter {
        padding-left: 30px;
        align-items: center;
    }
    .hand-img {
        width: 150%;
        height: auto;
        z-index: -1;
        position: relative;
    }
}


@media screen and (max-width: 800px) {
    
    .about_title {
        color:  #090F37;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 48px;
        margin-bottom: -10px;
        text-align: center;
        width: 113%;
    }
    
    .about_sub_titulo {
        margin-top: 22px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        color: #090F37;
        text-align: center;
    }

    .about-p-widht{
        width: 100%;
    }


    ul > li {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px; 
        color: #000000;
    }

    .list-mobile {
        padding-left: 20px !important;
    }

    .mb-mobile {
        margin-bottom: 70px;
    }

    .mobile-button-view {
        margin-top: 15px;
        text-align: center;
    }

    .start-left {
        text-align: left !important;
    }

    .handphone-mobile {
        height: 80%!important;
    }
}


