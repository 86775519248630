:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
}

#validation {
    margin-top: 70px;
}

#confirmation {
    margin-top: 20px;
    margin-bottom: 200px;
}

.subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #575F6E;
}

.confirm_subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #090F37;
}

.confirm-padding {
    margin-top: 30px;
    padding-left: 20%;
    padding-right: 20%;
}

.list_component1 {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
}

.list_component {
    width: 100%;
    padding-left: 28%;
    padding-right: 28%;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

ul>li>span {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 15px;
}

ul>li>span>p {
    float: right;
}

.list-size {
    color: #361FF5 !important;
}

@media screen and (max-width: 800px) {
    .list_component {
        width: 100%;
        padding-left: 0%;
        padding-right: 15%;
    }
    #confirmation {
        margin-top: 10px;
        margin-bottom: 380px;
    }
    .confirm-padding {
        margin-top: 30px;
        padding-left: 5%;
        padding-right: 5%;
    }
    .list-size {
        color: #361FF5 !important;
        font-size: 12px !important;
    }
}