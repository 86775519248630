:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
    --liberfin-border-color: #6188F7;
}

#preaprobado {
    margin-top: 60px;
    margin-bottom: 200px;
    padding-left: 5%;
    padding-right: 5%;
}

.upload-borders {
    border-color: var(--liberfin-border-color)!important;
    border: '1px dashed'!important;
    box-sizing: border-box;
}

.preptitle {
    color: #090F37;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    margin-bottom: -10px;
}

.preap-subtitle {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #090F37;
}

.color-blue {
    color: var(--liberfin-color);
}

.files-padding {
    padding-left: 5%;
    padding-right: 5%;
}

.button-end {
    text-align: end!important;
}

.upload {
    display: flex;
}

.w-60 {
    width: 60%;
}

.w-30 {
    width: 30%;
}

.upload_block {
    display: block;
}

.upload-grid {
    border: "1px dashed gray";
    padding: "8px 0px 0px 12px";
    margin-right: "10px";
    margin-bottom: "20px";
    min-height: "60px";
}

.upload_block>p {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #828282;
}

.upload_img {
    margin-right: 20px;
}

.upload-text {
    /*margin-right: 5%!important;*/
}

.upload-title {
    font-family: 'Poppins', 'sans-serif';
    font-style: 'normal';
    font-weight: 600;
    font-size: 13px;
    line-height: 4px;
    color: #090F37;
}

.btn-end {
    float: right;
    align-items: center;
}

.card-button-sm {
    background-color: transparent;
    border: 1px solid #361FF5;
    border-radius: 5px;
    color: var(--liberfin-color);
    width: 95px;
    height: 35px;
    padding: 5px 0px 5px 0px;
    outline: none;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.card-button-sm:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: #3498db;
    z-index: -1;
    transition: width 250ms ease-in-out;
}

.card-button-sm:hover {
    background-color: var(--liberfin-color);
    color: #ffffff;
}

.margin {
    margin-left: 16%;
    margin-right: 16%;
}

@media screen and (max-width: 959px) {
    .addScroll {
        overflow-y: scroll;
        height: 300px;
    }
    .circleResize {
        width: 50px;
        height: 50px;
    }
}

@media screen and (max-width: 820px) {
    #preaprobado {
        margin-top: 20px;
        padding-left: 5%;
        padding-right: 5%;
        margin-bottom: 400px;
    }
    .preptitle {
        color: #090F37;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        margin-bottom: -10px;
    }
    .preap-subtitle {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #090F37;
    }
    .card-button-sm {
        background-color: transparent;
        border: 1px solid #361FF5;
        border-radius: 5px;
        color: var(--liberfin-color);
        width: 80px;
        height: auto;
        padding: 5px 0px 5px 0px;
        outline: none;
        align-items: center;
        text-align: center;
        font-style: normal;
        font-size: 8px;
    }
    .upload-title {
        font-family: 'Poppins', 'sans-serif';
        font-style: 'normal';
        font-weight: 600;
        font-size: 9px;
        line-height: 4px;
        color: #090F37;
    }
    .upload-text {
        margin-right: -5%!important;
    }
    .upload_block>p {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 4px;
        color: #828282;
    }
    .upload_img {
        margin-right: 5px;
        height: auto;
    }
    .button-end {
        text-align: start!important;
    }
}