:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
}

#questions {
    margin-top: 60px;
    padding-left: 12%;
    padding-right: 12%;
    margin-bottom: 100px;
}

.question_container {
    margin-left: 7%;
    margin-right: 7%;
}

.qtitulo {
    color:  #090F37;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    margin-bottom: -10px;
}

.color-blue {
    color: var(--liberfin-color);
}

.qrow {
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
}

.qcenter {
    text-align: center;
    width: 100%;
}



.color-white {
    color: #fff;
}

.align-center {
    align-items: center!important;
}

.span_icon{
    padding-top: 6px;
    margin-left: 5px;
}

.col-6 > ul {
    list-style-type: none;
    margin: 0;
    text-align: left;
    margin-bottom: 40px;
    margin-left: -8.5%;
}

.card-button {
    background-color: transparent;
    border: 2px solid;
    border-color: var(--liberfin-color);
    border-radius: 5px;
    color: var(--liberfin-color);
    width: 250px;
    height: auto;
    padding: 5px 10px 5px 10px;
    outline: none;
    align-items: center;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

.card-button:hover{
    background-color: var(--liberfin-color);
    color: #ffffff;
    cursor: pointer;
}

.acordeon {
    margin-top: 80px;
}

.acordeon-item {
    background: #F7F7F7;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 1rem;
    text-align: left !important;
}

.acordeon-link {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    color: #090F37;
    text-decoration: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.answer {
    max-height: 0;
    overflow: hidden;
    position: relative;
    background-color: #F7F7F7;
    margin-top: 5px;
    padding-right: 8px;
    z-index: 1;
}

.answer > p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    text-align: justify;
    color: #4F4F4F
}

.acordeon-item:target .answer {
    max-height: 20rem;
}

.acordeon-item:target .acordeon-link i {
    display: none;
}

.upload {
    display: flex;
}

.w-60 {
    width: 60%;
}

.w-30 {
    width: 30%;
}

.btn-end {
    float: right;
    align-items: center;
}

.margin {
    margin-left: 16%;
    margin-right: 16%;
}

@media screen and (max-width: 800px) {
    #questions {
        margin-top: 10px;
        padding-left: 12%;
        padding-right: 12%;
    }

    .acordeon {
        margin-top: 20px;
    }
}