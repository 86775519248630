:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
}

#sectionblue {
    margin-bottom: 200px;
}

#sectionlaunch {
    height: 80vh;
    background-image: url('../../img/backLanch.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.column-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.color-blue {
    color: var(--liberfin-color);
}

.form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
}

.form-group label {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #090F37;
}

.form-group select {
    display: inline-block;
    width: 100%;
    height: 49px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border: 1px solid #6188F7;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-image: "";
}

.h-btn3 {
    background-color: var(--liberfin-color);
    border: 1px solid #FFFFFF;
    border-color: #FFFFFF;
    border-radius: 5px;
    color: #FFFFFF;
    width: 211px;
    height: 52px;
    left: 78%;
    top: 15px;
    padding: 5px 0px 5px 0px;
    outline: none;
    align-items: center;
    text-align: center;
    margin-top: 15px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #F7F7F7;
}

.h-btn3:hover {
    background-color: #FFFFFF;
    border-color: var(--liberfin-color-hover);
    color: var(--liberfin-color);
}

.card-blue {
    background-color: var(--liberfin-color);
    border: 1px solid var(--liberfin-color);
    width: 522px;
    height: auto;
    border-radius: 20px;
    /*padding: 35px 20px 15px 20px;*/
}

.card-content {
    text-align: center;
    /*padding: 5px 55px 5px 40px;*/
}

.card-content h3 {
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    color: #F7F7F7;
}

.pl-title {
    text-align: left;
}

.card_blue_title {
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: 700;
    font-size: 36.8px;
    line-height: 48px;
    color: #F7F7F7;
    align-items: flex-end;
}

.card_blue_subtitle {
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 23px;
    color: #F7F7F7;
    width: 80%;
}

.rechazado {
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: 700;
    font-size: 36.8px;
    line-height: 48px;
    color: #000;
}

.launch {
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: 700;
    font-size: 36.8px;
    line-height: 48px;
    color: #000;
}

.sub_titulo_launch {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #090F37;
    margin-top: 5px;
}

.card-white {
    background-color: #FFFFFF;
    border-radius: 10px;
    width: auto;
    height: auto;
    text-align: left;
    max-width: 350px;
}

.card-white>label {
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--liberfin-color);
}

.card-white>input[type=text] {
    left: calc(50% - 305px/2 - 12px);
    top: calc(50% - 59px/2);
    margin-bottom: 5px;
    width: 100%;
    height: 59px;
    border: 1px solid #6188F7;
    box-sizing: border-box;
    border-radius: 4px;
    background: #FFFFFF;
    font-size: 1rem;
    margin-right: 5px;
}

.card-white>input[type=checkbox] {
    margin-top: 15px;
}

.acepto-blue {
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    align-items: center;
    color: #090F37;
    margin-top: -10px;
}

@media screen and (max-width: 800px) {
    #sectionblue {
        margin-top: 0px;
        text-align: center;
        padding-left: 0;
        margin-bottom: 310px;
        background-color: var(--liberfin-color);
    }
    .diplayperson {
        display: none!important;
    }
    .ml-card-mobile {
        margin-left: 0%!important;
    }
    .mr-row {
        margin-right: 0!important;
    }
    .card-content {
        text-align: center;
        padding: 5px 1px 5px 0px;
    }
    .card-blue {
        background-color: var(--liberfin-color);
        border: 1px solid var(--liberfin-color);
        width: auto;
        height: auto;
        border-radius: 20px;
        padding: 20px;
    }
    .card_blue_title {
        font-size: 31px;
        line-height: 40px;
    }
    #footer {
        margin-top: 0px;
    }
    .modal-lf {
        top: 53% !important;
        left: 51% !important;
        padding: 0px 22px !important;
        width: 92% !important;
        height: 90% !important;
    }
}
@media screen and (min-width: 1551px) {
    #sectionrefused {
        height: 80vh;
    }
}
@media screen and (min-width: 1150px) and (max-width: 1550px) {
    #sectionrefused {
        margin-bottom: 180px;
    }
}
@media screen and (min-width: 1119px) and (max-width: 1149px) {
    #sectionrefused {
        margin-bottom: 180px;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1118px) {
    #sectionrefused {
        margin-bottom: 210px;
    }
    #sectionlaunch {
        margin-bottom: 70px;
    }
}
@media screen and (min-width: 900px) and (max-width: 1023px) {
    #sectionrefused {
        margin-bottom: 210px;
    }
    #sectionlaunch {
        margin-bottom: 70px;
    }
    .launch {
        font-family: 'Poppins', 'sans-serif';
        font-style: normal;
        font-weight: 700;
        font-size: 33px;
        line-height: 38px;
        color: #000;
    }
    .sub_titulo_launch {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        color: #090F37;
        margin-top: 5px;
    }
}
@media screen and (min-width: 768px) and (max-width: 899px) {
    #sectionrefused {
        margin-bottom: 210px;
    }
    #sectionlaunch {
        margin-bottom: 70px;
    }
    .launch {
        font-family: 'Poppins', 'sans-serif';
        font-style: normal;
        font-weight: 700;
        font-size: 29px;
        line-height: 35px;
        color: #000;
    }
    .sub_titulo_launch {
        font-family: 'Poppins', 'sans-serif';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #090F37;
        margin-top: 5px;
    }
}
@media screen and (min-width: 500px) and (max-width: 767px) {
    #sectionrefused {
        margin-bottom: 270px;
    }
    #sectionlaunch {
        margin-bottom: 70px;
    }
    .launch {
        font-family: 'Poppins', 'sans-serif';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 35px;
        color: #000;
    }
    .sub_titulo_launch {
        font-family: 'Poppins', 'sans-serif';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #000;
    }
}
@media screen and (min-width: 200px) and (max-width: 499px) {
    #sectionrefused {
        margin-bottom: 270px;
    }
    #sectionlaunch {
        padding-top: 35px !important;
        margin-bottom: 60px;
    }
    .launch {
        font-family: 'Poppins', 'sans-serif';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        color: #000;
    }
    .sub_titulo_launch {
        font-family: 'Poppins', 'sans-serif';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000;
    }
    .bar-mobile {
        width: 200px !important;
    }
}