:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
    --liberfin-border-color: #6188F7;
}

#firma {
    margin-top: 60px;
    padding-left: 15%;
    padding-right: 15%;
    margin-bottom: 200px;
}

@media screen and (max-width: 800px) {
    #firma {
        margin-top: 60px;
        padding-left: 5%;
        padding-right: 5%;
        margin-bottom: 350px;
    }
}