:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
    --liberfin-gray: #F6F6F6;
}

.fs-fiananciemiento-10 {
    font-size: 36px;
}

.fs-fiananciemiento-11 {
    font-size: 12px;
}

#section_f {
    margin-top: 60px;
    margin-bottom: 80px;
    width: 100%;
}

.shadow-price {
    box-shadow: 0px 8px 28px -6px rgba(124, 117, 201, 0.2), 0px 18px 88px -4px rgba(124, 117, 201, 0.2);
}

.custom-slider .MuiSlider-thumb {
    width: 25px;
    height: 25px;
    background-color: #F6F6F6;
    border: 6px solid #361FF5;
    box-shadow: 0px 5.42156px 18.9755px -4.06617px rgba(91, 82, 188, 0.25), 0px 12.1985px 59.6372px -2.71078px rgba(91, 82, 188, 0.2);
}

.custom-slider .MuiSlider-track {
    background: linear-gradient(270deg, #361FF5 3.97%, #6188F7 72.52%);
    border-radius: 8.46154px;
    border: none;
    height: 8px;
}

.custom-slider .MuiSlider-rail {
    background-color: #c0c0c0;
    height: 8px;
}

.container-financiamiento {
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
}

.padding-finan-title {
    padding-left: 8%;
    width: 110%;
}

.finan-title {
    color: #090F37;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    margin-bottom: -10px;
}

.sub_titulo {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #090F37;
    margin-top: 5px;
}

.color-blue {
    color: var(--liberfin-color);
}

.form {
    padding-left: 8%;
    padding-right: 10%;
}

.h-financiamiento {
    min-height: 30px !important;
}

.margin-barras {
    margin-top: 10px;
}

.barras {
    height: auto;
    margin-top: 1px!important;
    margin-right: -14px;
    margin-left: 0px;
    display: flex;
}

.margin-card-button {
    margin-top: 36px;
    margin-bottom: 30px;
}

.liquidez-card {
    max-width: 414px;
}

.labels_block {
    padding-bottom: 0px;
    padding-top: 20px;
}

.label_title {
    float: left;
    font-family: 'Poppins', 'sans-serif';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #090F37;
}

.label_subtitle {
    float: left;
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #090F37;
}

.labels span {
    float: right!important;
}

.labels_block .label_title {
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #090F37;
}

.rigth_price {
    float: inline-end;
    padding: 3px;
    font-size: 14px;
    font-weight: bold;
    margin-top: -8px;
}

.rigth_price>small {
    font-size: 10px;
    font-weight: normal;
}

.labels_block .label_subtitle {
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #090F37;
    margin-top: -4px;
}

.card-section {
    position: relative;
    text-align: center;
}

.web-padd-card {
    padding-left: 170px;
}

.card {
    text-align: center;
    box-shadow: 0px 8px 28px -6px rgba(124, 117, 201, 0.2), 0px 18px 88px -4px rgba(124, 117, 201, 0.2);
    transition: 0.3s;
    padding-top: 14px;
    padding-bottom: 40px;
    border-radius: 28px!important;
    border: none;
}

.card_title {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: #090F37;
    margin-bottom: 0%;
}

.title_content {
    padding: 15px 30px 15px 30px;
}

.card_title_blue {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: var(--liberfin-color);
    margin-bottom: 0%;
}

.monto {
    background-color: var(--liberfin-color);
    color: #FFF;
    font-family: 'Mulish', 'sans-serif';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 46px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 9px;
    margin-bottom: 20px;
}

.mxn {
    font-family: 'Mulish', 'sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-left: -10px;
}

.tasa {
    background-color: var(--liberfin-color);
    color: #FFF;
    font-family: 'Mulish', 'sans-serif';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 9px;
    margin-bottom: 20px;
}

.margin-financiamiento {
    margin-bottom: 20px;
}

.liquidez-card > ul {
    list-style-type: none;
    margin: 0;
    text-align: center;
    padding: 0 0 0 65px!important;
}

.card > .row > .col-md-12 > ul > li {
    text-align: center;
    align-items: center;
}


.card > .row > .col-md-12 > ul > li > i {
    text-align: center;
    align-items: center;
}

.card > .row > .col-md-12 > ul > li > span {
    text-align: center;
}


.card-button {
    background-color: transparent;
    border: 2px solid;
    border-color: var(--liberfin-color);
    border-radius: 5px;
    color: var(--liberfin-color);
    width: 250px;
    height: auto;
    padding: 5px 10px 5px 10px;
    outline: none;
    align-items: center;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

.seleccion {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
}

.seleccion select {
    display: inline-block;
    width: 100%;
    height: 49px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border: 1px solid #6188F7;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.seleccion select:focus {
    border-color: #6188F7;
    box-shadow: 0 0 0 0.2rem rgba(97, 144, 247, 0.25);
}

.bg-gray {
    background-color: var(--liberfin-gray);
    text-align: center;
}

.border-slider {
    border: '4px solid #361FF5'!important;
}

.fs-7 {
    font-size: 14px !important;
    font-family: 'Poppins', 'sans-serif';
}

.fs-6 {
    font-family: 'Poppins', 'sans-serif';
}

.fs-financiamiento-12 {
    font-size: 10px !important;
    font-weight: 500;
    font-family: 'Poppins', 'sans-serif';
}

.fs-financiamiento-13 {
    font-size: 20px !important;
    font-weight: 700;
    font-family: 'Poppins', 'sans-serif';
}

.fs-financiamiento-14 {
    font-size: 14px !important;
    font-family: 'Inter', 'sans-serif';
    font-size: 14px;
    line-height: 17px;
    color: #090F37;
}

.fs-financiamiento-15 {
    font-size: 15px !important;
    font-weight: 500;
    font-family: 'Poppins', 'sans-serif';
}

.init-responsive {
    display: none;
}
@media screen and (max-width: 820px) {
    .fs-7 {
        font-size: 11px !important;
        font-family: 'Poppins', 'sans-serif';
    }
}

@media screen and (max-width: 800px) {
    
    .monto {
        background-color: var(--liberfin-color);
        color: #FFF;
        font-family: Mulish, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 46px;
        line-height: 36px;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 20px;
    }
    .init-responsive {
        display: block;
    }
}

@media screen and (min-width: 768px) and (max-width: 799px) {
    #section_f {
        margin-top: 150px;
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .title_content {
        display: none!important;
    }

    .liquidez-card {
        max-width: 95%;
    }

    .mt-mobil {
        margin-top: 0rem!important;
    }

    .liquidez-card > ul {
        list-style-type: none;
        margin: 0;
        text-align: center;
    }

    .margin-card-button {
        margin-top: 10px!important;
        margin-bottom: 5px!important;
    }
}

@media screen and (max-width: 1199) {

    .margin-barras {
        margin-top: 0px!important;
    }

    .barras {
        height: auto;
        margin-top: 20px;
        margin-right: -14px;
        margin-left: 0px;
        display: flex;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199) {
    .tasa {
        background-color: var(--liberfin-color);
        color: #FFF;
        font-family: 'Mulish', 'sans-serif';
        font-style: normal;
        font-size: 25px!important;
        line-height: 30px!important;
        text-align: center;
        padding-top: 12px;
        padding-bottom: 9px;
        margin-bottom: 15px;
    }
}

@media screen and (width: 1300) {
    .finan-title {
        color: #090F37;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 33px!important;
        margin-bottom: 0px;
    }
}

@media (pointer: coarse) {
    .css-x3gekk-MuiSlider-root {
      padding: 20px 0px 0px 0px!important;
    }
}