:root {
    --liberfin-color: #361FF5;
    --liberfin-color-hover: #2712df;
}

#historias {
    margin-top: 60px;
    width: 100%;
    margin-bottom: 170px;
}

.container-histories {
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
}

.contenedor {
    display: flex;
}

.titulo {
    color: #090F37;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    margin-bottom: -10px;
}

.sub_titulo {
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #090F37;
    line-height: 28px;
}

.color-blue {
    color: var(--liberfin-color);
}

.mr {
    margin-right: 20px;
}

.profile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.star-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-container {
    text-align: center;
}

.card-description {
    width: 100%;
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: #828282;
}
.card-description-small {
    width: 100%;
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    color: #828282;
}

.MuiPaper-root {
    border: 1px solid #E0E0E0;
}

.card-person-name {
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #090F37;
}

.card-person-job {
    font-family: 'Poppins', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #828282;
}

.slider-responsive {
    display: flex;
    width: 130%!important;
    height: auto;
    position: relative;
    padding-right: -15px!important;
    margin-right: -25px!important;
}

.arrow-carrucel {
    border: none;
    background: none;
}

.card-border {
    border-radius: 10px!important;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
}

.width-card {
    width: 300px !important;
    margin-right: 20px;
}

.full-screen {
    display: flex;
}

.responsive-screen {
    display: none;
}

.MuiCardContent {
    padding: 16px 4px;
}

.MuiCardContent-root {
    padding-bottom: 4px !important;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
    position: absolute;
    top: 274px;
}

.alice-carousel__prev-btn {
    left: -66%;
}

.alice-carousel__next-btn {
    left: -59%;
}

.mx-historias {
    margin-left: 9.5px !important;
    margin-right: 9.5px !important;
}

.descr-height {
    height: 90px !important;
}

@media screen and (max-width: 1199px) {
    .alice-carousel__prev-btn {
        left: -69%!important;
    }
    
    .alice-carousel__next-btn {
        left: -59%;
    }
}
@media screen and (max-width: 800px) {
    #historias {
        margin-top: 20px;
        width: 100%;
    }
    .text-histories {
        padding-right: 0!important;
        ;
    }
    .frames {
        display: none;
    }
    .full-screen {
        display: none;
    }
    .responsive-screen {
        display: flex;
    }
    .slider-responsive {
        display: flex;
        width: 100%;
        height: auto;
        position: relative;
    }
}

@media screen and (min-width: 801px) and (max-width: 950px) {
    #historias {
        margin-top: 60px;
        width: 100%;
        margin-bottom: 280px;
    }
}

@media screen and (min-width: 336px) and (max-width: 799px) {
    #historias {
        margin-top: 60px;
        width: 100%;
        margin-bottom: 300px;
    }
}

@media screen and (min-width: 200px) and (max-width: 335px) {
    #historias {
        margin-top: 60px;
        width: 100%;
        margin-bottom: 310px;
    }
}